import styled from 'styled-components';
import { Themed } from '../../../theme';

export const Root = styled.div`
`;

export const BreakSection = styled.section`
  height: 10em;
  background: ${(props: Themed) => props.theme.colors.c};
  background-image: url("/donnie.jpg");
  background-position: left;
  background-repeat: no-repeat;
  filter: gray;
  -webkit-filter: grayscale(100%);
  background-size: cover;
  opacity: 0.2;
`;

export const TitleSection = styled.section`
  text-align: center;
  padding: 1em;
`;

export const RecommendationTitle = styled.h2`
  font-size: 2em;
  color: ${(props: Themed) => props.theme.colors.a};
`;

