import * as React from 'react';
import styled from 'styled-components';
import { FaBars, FaSmileWink } from 'react-icons/fa';
import { Themed } from '../../../theme';

export const HeaderRoot = styled.div`
  background-color: ${(props: Themed) => props.theme.colors.a};
  height: 70px;
  position: relative;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
`;

const Button = styled.button`
  border: 0;
  background: transparent;
  position: absolute;
  top: 1em;
  left: 1em;
  :hover svg path {
    fill: ${(props: Themed) => props.theme.colors.d};
  }
`;

export const MenuButton = (props: React.HTMLProps<HTMLButtonElement>) => (
  <Button onClick={props.onClick} type="button">
    <MenuIcon/>
  </Button>
);

export const MenuIcon = styled(FaBars)`
  fill: white;
  height: 2em;
  width: 2em;
`;

export const SmileIcon = styled(FaSmileWink)`
  position: absolute;
  top: 1em;
  right: 1em;
  fill: white;
  height: 2em;
  width: 2em;
`;
