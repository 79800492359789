import * as React from 'react';
import { SpinnerSection } from '../SpinnerSection';

export interface Props<T> {
  data: T | undefined;
  render: (value: T) => React.ReactNode;
}

export function WaitForData<T>(props: Props<T>) {
  return (
    props.data ? <>{props.render(props.data)}</> : <SpinnerSection/>
  );
};
