import { Recommendation } from '@recommendations/model';
import { domain } from './domain';
import { AuthenticationError } from './AuthenticationError';

export type UpdateRecommendationOptions = Recommendation;

export const updateRecommendation = ({ id, ...rest }: UpdateRecommendationOptions): Promise<Recommendation> => (
  fetch(`${domain}/api/v1/recommendations/${id}`, {
    body: JSON.stringify(rest),
    headers: { 'Content-Type': 'application/json' }, method: 'POST' }
  )
  .then(response => {
    if (response.status === 401) {
      throw new AuthenticationError();
    }
    return response.json()
  })
);
