import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Page } from '../Page/Page';
import {
  Title,
  RecommendationSection,
  Recommendation,
  Image,
  Tag,
  TagsSection
} from './styles';
import { capitalise } from '../../../utils';
import { WaitForCondition } from '../../sections/WaitForCondition';
import { useRecommendation, useSession } from '../../../state';
import { Button } from '../../controls';
import { useCallback } from 'react';

export interface Props {
  match: { params: { id: string } };
}

export const RecommendationPage: React.FC<Props> = (props) => {
  const { recommendation, isWaiting } = useRecommendation(props.match.params.id);
  const { session } = useSession();
  const { push } = useHistory();
  const onClickEdit = useCallback(() => push(`/admin/recommendations/${recommendation?.id}`), [recommendation]);
  return (
    <Page>
      <RecommendationSection>
        <WaitForCondition condition={!isWaiting}>
          {recommendation && (
            <Recommendation>
              <Title>{recommendation.title}</Title>
              
              {recommendation.genres && (
                <TagsSection>
                  {recommendation.genres.map(genre => <Tag key={genre}>{capitalise(genre)}</Tag>)}
                </TagsSection>
              )}
              
              <Image src={recommendation.image}/>
              
              {recommendation.content.map((paragraph, index) => 
                <p key={index}>{paragraph}</p>)}
                
              {session && <Button onClick={onClickEdit}>Edit</Button>}
            </Recommendation>
          )}
        </WaitForCondition>
      </RecommendationSection>
    </Page>
  );
};
