import { Recommendation } from '@recommendations/model';
import { domain } from './domain';

export interface RecommendationOptions {
  id: string;
}

export const toRecommendation = (jsonBody: any): Recommendation => ({
  id: jsonBody.id,
  category: jsonBody.category,
  author: jsonBody.author,
  title: jsonBody.title,
  content: jsonBody.content,
  genres: jsonBody.genres,
  image: jsonBody.image
});

export const getRecommendation = (options: RecommendationOptions): Promise<Recommendation> => (
  fetch(`${domain}/api/v1/recommendations/${options.id}`)
    .then(body => body.json())
    .then(toRecommendation)
);


