import React from 'react';
import styled, { css } from 'styled-components';
import * as Icons from 'react-icons/fa';
import {
  FaFilm,
  FaTv,
  FaBook,
  FaMusic,
  FaSpinner
} from 'react-icons/fa';
import { Themed } from '../theme';

const icon = css`
  width: 5em;
  height: 5em;
  fill: ${(props: Themed) => props.theme.colors.a};
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    width: 3em;
    height: 3em;
  }
`;

export const FilmIcon = styled(FaFilm)`${icon}`;

export const TVIcon = styled(FaTv)`${icon}`;

export const MusicIcon = styled(FaMusic)`${icon}`;

export const BookIcon = styled(FaBook)`${icon}`;

export const SpinnerIcon = styled(FaSpinner)`${icon}`;

export const mapCategoryIcon = () => {
  return MusicIcon;
};

export const createIcon = (name: string) => {
  const Icon = (Icons as any)[name];
  return styled(Icon)`${icon}`
}
