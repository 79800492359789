import { Reducer } from 'react';
import { Recommendation } from '@recommendations/model';

export type WorkingRecommendation = Omit<Recommendation, 'id'> & {
  readonly id?: string
};

export type TouchedFields = { [P in keyof WorkingRecommendation]?: boolean };

type State = {
  initial: WorkingRecommendation;
  current: WorkingRecommendation;
  touched: TouchedFields;
};

type Reset = {
  type: 'reset',
  recommendation: Recommendation;
};

type Initialise = {
  type: 'init',
  recommendation: Recommendation;
};

type ChangeFields = {
  type: 'change',
  update: Partial<Recommendation>;
};

type Actions = Reset | ChangeFields | Initialise;

const defaultRecommendation: WorkingRecommendation = {
  category: 'film',
  title: '',
  content: [],
};

export const defaultState: State = {
  initial: defaultRecommendation,
  current: defaultRecommendation,
  touched: {}
}

export const recommendationFormReducer: Reducer<State, Actions> = (state: State, action: Actions) => {
  switch (action.type) {
    case 'reset':
      return {
        ...state,
        current: state.initial
      };
    case 'init':
      return {
        ...state,
        initial: action.recommendation,
        current: action.recommendation
      };
    case 'change':
      return {
        ...state,
        current: {
          ...state.current,
          ...action.update
        },
        touched: {
          ...state.touched,
          ...Object.keys(action.update).reduce((current, key) => {
            current[key] = true;
            return current;
          }, {} as Record<string, boolean>)
        }
      };
  }
  return state;
}
