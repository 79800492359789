import * as React from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { SearchBox } from '../../controls/SearchBox';
import { Themed } from '../../../theme';

export const Root = styled.section`
  text-align: center;
  padding: 1em;
  height: 80px;
  ${SearchBox} {
    @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
      position: absolute;
      left: 4em;
      right: 1em;
    }
  }
`;

export const SearchIcon = styled(FaSearch)`
  color: ${(props: Themed) => props.theme.colors.a};
  height: 1.5em;
  width: 1.5em;
  margin-top: 1em;
  margin-right: 1em;
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    position: absolute;
    left: 1em;
  }
`;
