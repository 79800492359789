import { default as React, useContext, useState, createContext, useReducer, useCallback } from 'react';
import { Recommendation, RecommendationUpdate } from '@recommendations/model';
import { getRecommendations, GetRecommendationsOptions, getRecommendation, insertRecommendation, updateRecommendation } from '../services';
import { recommendationsReducer, RecommendationActions, create } from './recommendationsReducer';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { authenicated } from '../utils';

type RecommendationState = [ReadonlyArray<Recommendation>, React.Dispatch<RecommendationActions>];

const defaultState: RecommendationState = [[], (action) => undefined];

const RecommendationsContext = createContext(defaultState);

export const RecommendationsProvider = (props: { children: React.ReactNode }) => {
  const state = useReducer(recommendationsReducer, []);
  return (
    <RecommendationsContext.Provider value={state}>
      {props.children}
    </RecommendationsContext.Provider>
  );
};

export const useRecommendations = () => {
  const [isWaiting, setIsWaiting] = useState(false);
  const [state, update] = useContext(RecommendationsContext);
  const { push } = useHistory();
  
  const refreshRecommendations = async (args?: GetRecommendationsOptions) => {
    setIsWaiting(true);
    const recommendations = await getRecommendations(args);
    setIsWaiting(false);
    update(create.refresh(recommendations));
  };
  
  const _addRecommendation = useCallback(async (values: RecommendationUpdate) => {
    return await authenicated(() => insertRecommendation(values), push);
  }, []);
  
  const _updateRecommendation = useCallback(async (values: Recommendation) => {
    return await authenicated(() => updateRecommendation(values), push);
  }, []);
  
  return {
    recommendations: state,
    refreshRecommendations,
    addRecommendation: _addRecommendation,
    updateRecommendation: _updateRecommendation,
    isWaiting
  };
};

export const useRecommendation = (id: string) => {
  const [isWaiting, setIsWaiting] = useState(false);
  const [state, setState] = useContext(RecommendationsContext);
  const recommendation = state.find(i => i.id === id);
  useEffect(() => {
    if (!recommendation) {
      setIsWaiting(true);
      getRecommendation({ id })
        .then((recommendation) => setState(create.update([recommendation])))
        .finally(() => setIsWaiting(false));
    }
  }, []);
  return {
    recommendation,
    isWaiting
  };
};
