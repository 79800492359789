import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Page } from '../Page/Page';
import {
  TitleField,
  RecommendationSection,
  Recommendation,
  Form,
  FormRow,
  LoginButton,
  CancelButton
} from './styles';
import { WaitForCondition } from '../../sections/WaitForCondition';
import { useLoginForm } from './loginForm';
import { useCallback } from 'react';
import { useSession } from '../../../state';

export interface Props {
  match: { params: { id: string } };
}

export const LoginPage: React.FC<Props> = (props) => {
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const form = useLoginForm();
  const { push } = useHistory();
  const { login } = useSession();
  const { username, password } = form.values;
  const canSubmit = Boolean(username && password && !form.errors);
  const onChangeUsername = useCallback((e) => form.change('username', e.target.value), []);
  const onChangePassword = useCallback((e) => form.change('password', e.target.value), []);
  const onClickCancel = useCallback((e) => undefined, []);
  const onClickLogin = useCallback(async () => {
    if (username && password) {
      try {
        await login({ username, password });
        push('/');
      } catch (err) {
        setLoginError('login error');
      }
    }
  }, [form.values]);
  return (
    <Page>
      <RecommendationSection>
        <WaitForCondition condition={true}>
          <Recommendation>
            <Form>
              <FormRow>
                <TitleField
                  value={username} 
                  {...(form.errors?.username)}
                  label="Username"
                  onChange={onChangeUsername}
                  onBlur={onChangeUsername}
                />
              </FormRow>
              <FormRow>
                <TitleField
                  value={password} 
                  {...(form.errors?.password)}
                  label="Password"
                  onChange={onChangePassword}
                  onBlur={onChangePassword}
                />
              </FormRow>
              {loginError}
              <FormRow>
                <CancelButton onClick={onClickLogin} disabled={!canSubmit}>Login</CancelButton>
                <LoginButton onClick={onClickCancel}>Cancel</LoginButton>
              </FormRow>
            </Form>
          </Recommendation>
        </WaitForCondition>
      </RecommendationSection>
    </Page>
  );
};
