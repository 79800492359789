export interface Themed {
  theme: Theme;
};

export interface Theme {
  colors: {
    a: string;
    b: string;
    c: string;
    d: string;
    e: string;
    f: string;
  },
  breakpoints: {
    xs: string;
  }
}

export const theme: Theme = {
  colors: {
    a: '#4D7514',
    b: '#FFFFFF',
    c: '#F8F8F8',
    d: '#D0D0D0',
    e: '#75144C',
    f: '#2E4E00',
  },
  breakpoints: {
    xs: '480px',
  }
};
