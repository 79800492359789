
import * as React from 'react';
import styled from 'styled-components';
import { Themed } from '../../../theme';

const Root = styled.div`
  text-align: center;
  color: ${(props: Themed) => props.theme.colors.a};
  font-size: 1.5em;
  font-weight: bold;
`;

interface Props {
  message?: string;
}

export const NoResultSection = (props: Props) => (
  <Root>{props.message || 'No Results'}</Root>
);
