import { default as React, useContext, useState, createContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Session } from '@recommendations/model';
import { login, LoginOptions } from '../services';

type SessionState = [Session | undefined, (update: Session) => void];

const defaultState: SessionState = [undefined, () => undefined];

export const SessionContext = createContext(defaultState);

export const UserDetailsProvider = (props: { children: React.ReactNode }) => {
  const state = useState<Session | undefined>(undefined);
  return (
    <SessionContext.Provider value={state}>
      {props.children}
    </SessionContext.Provider>
  );
};

type UseSessionOptions = {
  readonly redirectIfUnauthenticated?: boolean;
};

export const useSession = (options?: UseSessionOptions) => {
  const { push } = useHistory();
  const [state, update] = useContext(SessionContext);
  
  if(options?.redirectIfUnauthenticated) {
    if (!state) {
      push('/login')
    }
  };
  
  const _login = useCallback(async (options: LoginOptions) => {
    const session = await login(options);
    update(session);
  }, [state])

  return {
    session: state,
    login: _login,
  };
};
