import { Category } from '@recommendations/model';
import { domain } from './domain';

export const toCategory = (jsonBody: any): Category => ({
  id: jsonBody.id,
  name: jsonBody.name,
  icon: jsonBody.icon
});

export const getCategories = (): Promise<readonly Category []> => (
  fetch(`${domain}/api/v1/categories`)
    .then(body => body.json())
    .then((jsonCollection: any) => jsonCollection.map(toCategory))
);


