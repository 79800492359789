import styled from 'styled-components';

export const Root = styled.div.attrs({ className: 'container' })`
  padding-top: 3em;
  padding-bottom: 3em;
`;

export const CategoriesRow = styled.div.attrs({ className: 'row' })`
  
`;
