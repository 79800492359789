import * as React from 'react';
import { Root, Content } from './styles';
import { Header } from '../../sections/Header';
import { Footer } from '../../sections/Footer';
import { useState } from 'react';
import { Menu } from '../../controls/Menu';

export interface Props {
  children: React.ReactNode;
}

export const Page: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Root>
      <Menu
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <Header onOpenMenu={() => setIsOpen(true)}/>
      <Content>
        {props.children}
      </Content>
      <Footer/>
    </Root>
  );
};
