import { default as React, useContext, useState, createContext, useEffect } from 'react';
import { Category } from '@recommendations/model';
import { getCategories } from '../services';

type CategoriesState = [ReadonlyArray<Category>, (update: ReadonlyArray<Category>) => void];

const defaultState: CategoriesState = [[], () => undefined];

export const CategoriesContext = createContext(defaultState);

export const CategoriesProvider = (props: { children: React.ReactNode }) => {
  const state = useState<ReadonlyArray<Category>>([]);
  return (
    <CategoriesContext.Provider value={state}>
      {props.children}
    </CategoriesContext.Provider>
  );
};

export const useCategories = () => {
  const [state, update] = useContext(CategoriesContext);
  useEffect(() => {
    getCategories()
      .then((categories) => update(categories.map(category => (category))));
  }, []);
  return { categories: state };
};
