import * as React from 'react';
import Sidebar from 'react-sidebar';
import { MenuItems, MenuItem } from './styles';
import { FaHome, FaSmile, FaUserAlt } from 'react-icons/fa';

export interface Props {
  open: boolean;
  onClose: () => void;  
}

export const Menu: React.FC<Props> = (props) => (
  <Sidebar
    sidebar={
      <MenuItems>
        <MenuItem to="/">
          <FaHome/>Home
        </MenuItem>
        <MenuItem to="/recommendations/">
          <FaSmile/>
          Recommendations
        </MenuItem>
        <MenuItem to="/login/">
          <FaUserAlt/>
          Login
        </MenuItem>
      </MenuItems>
    }
    open={props.open}
    styles={{
      sidebar: {
        background: 'white'
      }
    }}
    onSetOpen={() => props.onClose()}
  ><></></Sidebar>
);
