import styled from 'styled-components';
import { Themed } from '../../../theme';
import { TextField, Button } from '../../controls';

export const TitleField = styled(TextField)`
  margin-top: 2em;
  font-size: 1.5em;
  margin-bottom: 1em;
  color: ${(props: Themed) => props.theme.colors.a};
`;

export const RecommendationTextField = styled(TextField).attrs({ multiline: true })`
  width: 100%;
`;

export const Recommendation = styled.section`
  max-width: 30em;
  font-size: 1.25em;
`;

export const RecommendationSection = styled.section`
  padding-left: 4em;
  padding-right: 1em;
  p {
    color: ${(props: Themed) => props.theme.colors.a};
  }
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    padding-left: 1em;
  }
`;

export const TagsField = styled(TextField)`
`;

export const FormRow = styled.div`
`;

export const Form = styled.section`
  margin-top: 20px;
  ${FormRow} {
    margin-bottom: 20px;
  }
`;

export const CreateButton = styled(Button)``;

export const SaveButton = styled(Button)``;