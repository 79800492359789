import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Page } from '../Page/Page';
import { Select, MenuItem } from '../../controls';
import {
  TitleField,
  RecommendationSection,
  Recommendation,
  RecommendationTextField,
  TagsField,
  Form,
  FormRow,
  CreateButton,
  SaveButton
} from './styles';
import { WaitForCondition } from '../../sections/WaitForCondition';
import { useRecommendationForm } from './recommendationForm';
import { getRecommendation } from '../../../services';
import { useCategories, useRecommendations, useSession } from '../../../state';
import { useValidation } from './recommendationFormValidation';

export interface Props {
  match: { params: { id: string } };
}

const fromContent = (value: string [] | undefined) => value ? value.join('\n\n') : '';
const toContent = (value: string | undefined) => value ? value.split('\n\n') : [];
const fromGenres = (value: string [] | undefined) => value ? value.join(', ') : '';
const toGenres = (value: string | undefined) => value ? value.split(', ') : [];

export const RecommendationEditPage: React.FC<Props> = (props) => {
  useSession({ redirectIfUnauthenticated: true });
  const { categories } = useCategories();
  const { addRecommendation, updateRecommendation } = useRecommendations();
  const form = useRecommendationForm();
  const errors = useValidation(form);
  const { id, title, content, genres, category } = form.values;
  const [isWaiting, setIsWaiting] = useState(false);
  useEffect(() => {
    (async () => {
      setIsWaiting(true);
      if (props.match.params.id) {
        const recommendation = await getRecommendation({ id: props.match.params.id });
        if (recommendation) {
          form.init(recommendation);
        }
      }
      setIsWaiting(false);
    })();
  }, []);
  
  const onChangeTitleText = useCallback((e) => form.change('title', e.target.value), []);
  const onChangeContent = useCallback((e) => form.change('content', toContent(e.target.value)), []);
  const onChangeGenres = useCallback((e) => form.change('genres', toGenres(e.target.value)), []);
  const onChangeCategory = useCallback((e) => form.change('category', e.target.value), []);
  const onClickCreate = useCallback(async () => {
    const { id } = await addRecommendation(form.values);
    form.change('id', id);
  }, [form.values]);
  const onClickSave = useCallback(async () => {
    id && await updateRecommendation({ id, ...form.values });
  }, [form.values]);
  return (
    <Page>
      <RecommendationSection>
        <WaitForCondition condition={!isWaiting}>
          <Recommendation>
            <Form>
              <FormRow>
                <TitleField
                  value={title} 
                  {...(errors?.title)}
                  label="Title"
                  onChange={onChangeTitleText}
                  onBlur={onChangeTitleText}
                />
              </FormRow>
              <FormRow>
                <Select value={category} onChange={onChangeCategory}>
                  {categories.map((category) => 
                    <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)}
                </Select>
              </FormRow>
              <FormRow>
                <TagsField
                  value={fromGenres(genres)}
                  {...(errors?.genres)}
                  label="Genres"
                  onChange={onChangeGenres}
                  onBlur={onChangeGenres}
                />
              </FormRow>
              <FormRow>
                <RecommendationTextField
                  value={fromContent(content)}
                  {...(errors?.content)}
                  label="Content"
                  onChange={onChangeContent}
                  onBlur={onChangeContent}
                />
              </FormRow>
              <FormRow>
                {id ?
                  <SaveButton onClick={onClickSave} disabled={Boolean(errors)}>Save</SaveButton> :
                  <CreateButton onClick={onClickCreate} disabled={Boolean(errors)}>Create</CreateButton>
                }
              </FormRow>
            </Form>
          </Recommendation>
        </WaitForCondition>
      </RecommendationSection>
    </Page>
  );
};
