import { AuthenticationError } from '../services/AuthenticationError';

export const capitalise = (value: string) => value.split(' ')
  .map(word => `${word[0].toUpperCase()}${word.substr(1)}`)
  .join(' ');

export const verify = <T>(value: T | undefined, message = 'value can be undefined'): T => {
  if (!value) {
    throw new Error(message);
  }
  return value;
};

export const authenicated = <T>(action: () => T, redirect: (value: string) => void): T => {
  try {
    return action();
  } catch (err) {
    if (err instanceof AuthenticationError) {
      redirect('/');
    }
    throw err;
  }
};
