import * as React from 'react';
import { HeaderRoot, MenuButton, SmileIcon } from './styles';

export interface DispatchProps {
  onOpenMenu: () => void;
}

export type Props = DispatchProps;

export const Header: React.FC<Props> = (props: Props) => (
  <HeaderRoot>
    <MenuButton onClick={props.onOpenMenu}/>
  </HeaderRoot>
);
