import { default as React} from 'react';
import { Root, RecommendationsRow } from './RecommendationsSectionStyles';
import { ReviewTile } from '../../controls/ReviewTile';
import { useRecommendations } from '../../../state';
import { CategoryId } from '@recommendations/model';
import { WaitForCondition } from '../WaitForCondition';
import { useEffect } from 'react';

export interface Props {
  readonly category?: CategoryId;
  readonly searchString?: string;
}

export const RecommendationsSection: React.FC<Props> = (props) => {
  const { recommendations, refreshRecommendations, isWaiting } = useRecommendations();
  useEffect(() => {
    refreshRecommendations(props);
  }, [props.searchString, props.category]);
  return (
    <Root>
      <RecommendationsRow>
        <WaitForCondition condition={!isWaiting}>
          {recommendations && recommendations.map((recommendation) => (
            <ReviewTile
              key={recommendation.id}
              id={recommendation.id}
              title={recommendation.title}
              image={recommendation.image || '/recommendation.png'}
            />
          ))}
        </WaitForCondition>
      </RecommendationsRow>
    </Root>
  );
};
