import { useReducer } from 'react';
import { loginFormReducer, LoginFormValues, defaultState, TouchedFields } from './loginFormReducer';

type ErrorInfo = { helperText: string, error: boolean };

type Errors = { [P in keyof LoginFormValues]?: ErrorInfo };

type LoginForm = {
  errors?: Errors;
  values: LoginFormValues;
  touchedFields: TouchedFields;
  change: <T extends keyof LoginFormValues>(key: T, value: LoginFormValues[T]) => void;
  reset: (values: LoginFormValues) => void;
  init: (values: LoginFormValues) => void;
};

const validateForm = (values: LoginFormValues, touchedFields: TouchedFields): Errors | undefined => {
  if (touchedFields.username && values.username === '') {
    return { username: { helperText: 'You must supply a username', error: true } }
  }
  if (touchedFields.password && values.password === '') {
    return { password: { helperText: 'You must supply a password', error: true } }
  }
  return undefined;
};

export const useLoginForm = (): LoginForm => {
  const [state, dispatch] = useReducer(loginFormReducer, defaultState);
  const errors = validateForm(state.current, state.touched);
  return {
    errors,
    values: state.current,
    touchedFields: state.touched,
    change: <T extends keyof LoginFormValues>(key: T, value: LoginFormValues[T]) => {
      dispatch({ type: 'change', update: { [key]: value } });
    },
    reset: (values: LoginFormValues) => {
      dispatch({ type: 'reset', values })
    },
    init: (values: LoginFormValues) => {
      dispatch({ type: 'init', values })
    }
  };
};
