import styled from 'styled-components';
import { Themed } from '../../../theme';
import { Link } from 'react-router-dom';

export const Root = styled.div.attrs({ className: 'col-sm' })`
  margin-bottom: 1em;
`;

export const StyledLink = styled(Link)`
  :hover {
    h2 {
      color: ${(props: Themed) => props.theme.colors.f};
    }
    svg {
      fill: ${(props: Themed) => props.theme.colors.f};
    }
    text-decoration: none;
  }
`;

export const Title = styled.h2`
  color: ${(props: Themed) => props.theme.colors.a};
  font-size: 1.5em;
  display: block;
  margin-top: 0.5em;
  margin-left: 0.25em;
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    display: inline-block;
    margin-left: 1em;
    max-width: 5em;
  }
`;

export const Badge = styled.div`
  position: absolute;
  top: 0;
  left: 80px;
  color: ${(props: Themed) => props.theme.colors.b}
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  background: ${(props: Themed) => props.theme.colors.e};
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    top: 0;
    left: 50px;
    width: 25px;
    height: 25px;
    font-size: 0.75em;
  }
`;
