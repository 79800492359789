import { default as React, useCallback, useState } from 'react';
import { Page } from '../Page/Page';
import { SearchSection, ChangeArgs } from '../../sections/SearchSection';
import { RecommendationsSection} from '../../sections/RecommendationsSection';
import { useRecommendations } from '../../../state';
import { NoResultSection } from '../../sections/NoResultSection';

export interface Props {} 

export const RecommendationsPage: React.FC<Props> = (props: Props) => {
  const [searchString, setSearchString] = useState<string | undefined>('');
  const onChange = useCallback((args: ChangeArgs) => setSearchString(args.target.value), []);
  const { recommendations } = useRecommendations();
  return (
    <Page>
      <SearchSection onChange={onChange}/>
      <RecommendationsSection searchString={searchString}/>
      {recommendations.length === 0 && <NoResultSection/>}
    </Page>
  );
};