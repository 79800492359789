import React, { useEffect } from 'react';
import { MainPage } from './components/pages/MainPage/MainPage';
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { ThemeProvider } from'styled-components';
import { theme } from './theme';
import { RecommendationPage } from './components/pages/RecommendationPage';
import { RecommendationsPage } from './components/pages/RecommendationsPage';
import { CategoryPage } from './components/pages/CategoryPage';
import { RecommendationEditPage } from './components/pages/RecommendationEditPage';
import {
  RecommendationsProvider,
  CategoriesProvider as CategoriesProvider,
  UserDetailsProvider
} from './state';
import { LoginPage } from './components/pages/LoginPage';

const Routes = () => {
  return (
    <Router>
      <Route path='/' exact={true} component={MainPage}/>
      <Route path='/recommendations' exact={true} component={RecommendationsPage}/>
      <Route path='/recommendations/:id' component={RecommendationPage}/>
      <Route path='/admin/recommendations' exact={true} component={RecommendationEditPage}/>
      <Route path='/admin/recommendations/:id' component={RecommendationEditPage}/>
      <Route path='/category/:id' component={CategoryPage}/>
      <Route path='/login' component={LoginPage}/>
    </Router>
  );
};

export const App = () => {
  return (
    <UserDetailsProvider>
      <RecommendationsProvider>
        <CategoriesProvider>
          <ThemeProvider theme={theme}>
            <Routes/>
          </ThemeProvider>
        </CategoriesProvider>
      </RecommendationsProvider>
    </UserDetailsProvider>
  );
};
