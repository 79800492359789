import { Recommendation } from '@recommendations/model';
import { domain } from './domain';
import { AuthenticationError } from './AuthenticationError';

export type InsertRecommendationOptions = Omit<Recommendation, 'id'>;

export const insertRecommendation = (options: InsertRecommendationOptions): Promise<Recommendation> => (
  fetch(`${domain}/api/v1/recommendations`, {
    body: JSON.stringify(options),
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT'
  })
  .then(response => {
    if (response.status === 401) {
      throw new AuthenticationError();
    }
    return response.json()
  })
);
