import * as React from 'react';
import { Title, Root, Badge } from './styles';
import { StyledLink } from './styles';
import { IconType } from 'react-icons';

export interface StateProps {
  id?: string;
  title: string;
  icon: IconType;
  count?: number;
}

export type Props = StateProps;

export const CategoryTile = (props: Props) => {
  const Icon = props.icon;
  return (
    <Root>
      <StyledLink to={`/category/${props.id}`}>
        <Icon/>
        <Title>{props.title}</Title>
        {(props.count && props.count > 0) ? <Badge>{props.count}</Badge> : null}
      </StyledLink>
    </Root>
  );
};
