import { Reducer } from 'react';

export type LoginFormValues = {
  readonly username?: string;
  readonly password?: string;
};

export type TouchedFields = { [P in keyof LoginFormValues]?: boolean };

type State = {
  initial: LoginFormValues;
  current: LoginFormValues;
  touched: TouchedFields;
};

type Reset = {
  type: 'reset',
  values: LoginFormValues;
};

type Initialise = {
  type: 'init',
  values: LoginFormValues;
};

type ChangeFields = {
  type: 'change',
  update: Partial<LoginFormValues>;
};

type Actions = Reset | ChangeFields | Initialise;

const defaultLoginFormValues: LoginFormValues = {
  username: '',
  password: '',
};

export const defaultState: State = {
  initial: defaultLoginFormValues,
  current: defaultLoginFormValues,
  touched: {}
}

export const loginFormReducer: Reducer<State, Actions> = (state: State, action: Actions) => {
  switch (action.type) {
    case 'reset':
      return {
        ...state,
        current: state.initial
      };
    case 'init':
      return {
        ...state,
        initial: action.values,
        current: action.values
      };
    case 'change':
      return {
        ...state,
        current: {
          ...state.current,
          ...action.update
        },
        touched: {
          ...state.touched,
          ...Object.keys(action.update).reduce((current, key) => {
            current[key] = true;
            return current;
          }, {} as Record<string, boolean>)
        }
      };
  }
  return state;
}
