import * as React from 'react';
import { RecommendationsSection } from '../../sections/RecommendationsSection';
import { Page } from '../Page';
import { Title, TitleSection } from './CategoryPageStyles';
import { createIcon } from '../../../utils';
import { useCategories } from '../../../state';
import { WaitForData } from '../../sections/WaitForData';

export interface Props {
  match: { params: { id: string } };
}

export const CategoryPage: React.FC<Props> = (props) => {
  const { categories } = useCategories();
  const category = categories.find(category => category.id === props.match.params.id);
  const Icon = category ? createIcon(category.icon) : () => <></>;
  return (
    <Page>
      <WaitForData data={category} render={(category) =>
        <>
          <TitleSection>
            <Icon/>
            <Title>{category.name}</Title>
          </TitleSection>
          <RecommendationsSection category={category.id}/>
        </>
      }/>
    </Page>
  );
};
