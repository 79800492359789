import { useMemo } from 'react';
import { Form } from './recommendationForm';
import { WorkingRecommendation } from './recommendationFormReducer';

type ErrorInfo = { helperText: string, error: boolean };

type Errors = { [P in keyof WorkingRecommendation]?: ErrorInfo };

const validate = (form: Form): Errors | undefined => {
  if (form.touchedFields.title && form.values.title === '') {
    return { title: { helperText: 'You must supply a title', error: true } }
  }
  if (form.touchedFields.genres && (!form.values.genres || form.values.genres.length === 0)) {
    return { genres: { helperText: 'You must supply genres', error: true } }
  }
  if (form.touchedFields.content && (!form.values.content || form.values.content.length === 0)) {
    return { content: { helperText: 'You must supply content', error: true } }
  }
  return undefined;
};

export const useValidation = (form: Form) => {
  return useMemo(() => validate(form), [form]);
}