import * as React from 'react';
import { RecommendationsSection } from '../../sections/RecommendationsSection';
import { CategoriesSection } from '../../sections/CategoriesSection';
import { Page } from '../Page';
import {
  BreakSection,
  RecommendationTitle,
  TitleSection
} from './styles';

export const MainPage: React.FC = () => {
  return (
    <Page>
      <CategoriesSection/>
      <BreakSection/>
      <TitleSection>
        <RecommendationTitle>Latest</RecommendationTitle>
      </TitleSection>
      <RecommendationsSection/>
    </Page>
  );
};
