import { Session } from '@recommendations/model';
import { domain } from './domain';

export type LoginOptions = {
  readonly username: string;
  readonly password: string;
};

export const login = (options: LoginOptions): Promise<Session> => (
  fetch(`${domain}/api/v1/login`, { body: JSON.stringify(options), headers: { 'Content-Type': 'application/json' }, method: 'POST' })
    .then(body => body.json())
);
