import styled from 'styled-components';
import { Themed } from '../../../theme';

export const Title = styled.h2`
  margin-top: 2em;
  font-size: 1.5em;
  margin-bottom: 1em;
  color: ${(props: Themed) => props.theme.colors.a};
`;

export const Image = styled.img`
  border-radius: 5px;
  float: right;
  margin-left: 1em;
  margin-bottom: 1em;
  height: 10em;
  width: 10em;
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    height: 6em;
    width: 6em;
  }
`;

export const Recommendation = styled.section`
  max-width: 30em;
  font-size: 1.25em;
`;

export const RecommendationSection = styled.section`
  padding-left: 4em;
  padding-right: 1em;
  p {
    color: ${(props: Themed) => props.theme.colors.a};
  }
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    padding-left: 1em;
  }
`;

export const TagsSection = styled.section`
  margin-bottom: 1em;
  div {
    margin-right: 0.5em;
  }
`;

export const Tag = styled.div`
  border-radius: 5px;
  padding: 0.25em;
  display: inline-block;
  background: ${(p: Themed) => p.theme.colors.e};
  color: ${(p: Themed) => p.theme.colors.b};
`;