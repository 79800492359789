import { default as React } from 'react';
import { Root, SearchIcon } from './styles';
import { SearchBox } from '../../controls/SearchBox';
import { useDebouncedCallback } from 'use-debounce';
import { useRef } from 'react';

export type ChangeArgs = {
  target: {
    value: string;
  }
};

export interface Props {
  onChange?: (args: ChangeArgs) => void;
}

export const SearchSection = (props: Props) => {
  const textBox = useRef<HTMLInputElement>();
  const [onChange] = useDebouncedCallback(() => props.onChange && props.onChange({
    target: {
      value: textBox.current ? textBox.current.value : ''
    }
  }), 1000);
  return (
    <Root>
      <SearchIcon/>
      <SearchBox inputRef={textBox} onChange={onChange}/>
    </Root>
  );
};