import { Recommendation } from '@recommendations/model';
import { useReducer } from 'react';
import { recommendationFormReducer, defaultState } from './recommendationFormReducer';

export const useRecommendationForm = () => {
  const [state, dispatch] = useReducer(recommendationFormReducer, defaultState);
  return {
    values: state.current,
    touchedFields: state.touched,
    change: <T extends keyof Recommendation>(key: T, value: Recommendation[T]) => {
      dispatch({ type: 'change', update: { [key]: value } });
    },
    reset: (recommendation: Recommendation) => {
      dispatch({ type: 'reset', recommendation })
    },
    init: (recommendation: Recommendation) => {
      dispatch({ type: 'init', recommendation })
    }
  }
}

export type Form = ReturnType<typeof useRecommendationForm>;