import styled from 'styled-components';
import { Themed } from '../../../theme';
import { Link } from 'react-router-dom';

export const Root = styled.div.attrs({ className: 'col-sm' })`
  margin-bottom: 1em;
`;

export const StyledLink = styled(Link)`
  :hover {
    h2 {
      color: ${(props: Themed) => props.theme.colors.f};
    }
    text-decoration: none;
  }
`;

export const Image = styled.img`
  border-radius: 5px;
  height: 6em;
  width: 6em;
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    vertical-align: top;
    height: 4em;
    width: 4em;
  }
`;

export const Title = styled.h2`
  margin-top: .75em;
  color: ${(props: Themed) => props.theme.colors.a};
  font-size: 1.5em;
  display: block;
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    display: inline-block;
    margin-left: 1em;
    max-width: 11em;
  }
`;