import * as React from 'react';
import { SpinnerSection } from '../SpinnerSection';

export interface Props {
  condition: boolean;
  children: React.ReactNode;
}

export function WaitForCondition(props: Props) {
  return (
    props.condition ? <>{props.children}</> : <SpinnerSection/>
  );
};
