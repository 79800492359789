import * as React from 'react';
import { Image, Title, Root } from './styles';
import { StyledLink } from './styles';

export interface StateProps {
  id?: string;
  title: string;
  image: string;
}

export type Props = StateProps;

export const ReviewTile = (props: Props) => (
  <Root>
    <StyledLink to={`/recommendations/${props.id}`}>
      <Image src={props.image}/>
      <Title>{props.title}</Title>
    </StyledLink>
  </Root>
);
