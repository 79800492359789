import React from 'react';
import { Spinner } from '../../controls/Spinner';
import styled from 'styled-components';

const Root = styled.div`
  margin-top: 4em;
  margin-bottom: 4em;
  text-align: center;
  min-height: 500px;
  width: 100%;
`;

export const SpinnerSection: React.FC = () => (
  <Root>
    <Spinner/>
  </Root>
);
