import * as React from 'react';
import { FooterRoot } from './FooterStyles';

export type Props = {};

export const Footer: React.FC<Props> = (props: Props) => (
  <FooterRoot>
    
  </FooterRoot>
);
