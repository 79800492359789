import { Recommendation } from '@recommendations/model';

export enum RecommendationActionsEnum {
  update = 'recommendations/update',
  refresh = 'recommendations/refresh'
}

export type Update = {
  type: RecommendationActionsEnum.update;
  recommendations: readonly Recommendation [];
}

export type Refresh = {
  type: RecommendationActionsEnum.refresh;
  recommendations: readonly Recommendation [];
}

export type RecommendationActions = Update | Refresh;

export const create = {
  update: (recommendations: readonly Recommendation []): Update => ({
    type: RecommendationActionsEnum.update,
    recommendations
  }),
  refresh: (recommendations: readonly Recommendation []): Refresh => ({
    type: RecommendationActionsEnum.refresh,
    recommendations
  })
};

export const recommendationsReducer: React.Reducer<readonly Recommendation [], RecommendationActions> = (state, action) => {
  let newState: Recommendation [] | undefined = undefined;
  if (action.type === RecommendationActionsEnum.update) {
    action.recommendations.forEach(recommendation => {
      const oldIndex = state.findIndex(r => r.id === recommendation.id);
      const newRecommendation = recommendationReducer(state[oldIndex], recommendation);
      if (newRecommendation !== state[oldIndex] && !newState) {
        newState = [...state];
      }
      if (newState) {
        if (oldIndex === -1) {
          newState.push(newRecommendation)
        } else {
          newState[oldIndex] = newRecommendation;
        }
      }
    });
    return newState && newState !== state ? newState : state;
  } else if (action.type === RecommendationActionsEnum.refresh) {
    return action.recommendations;
  }
  return state;
};

const recommendationReducer = (state: Recommendation, action: Recommendation) => {
  return action;
};
