import { default as React } from 'react';
import { useCategories } from '../../../state/categories';
import { Root, CategoriesRow } from './CategoriesSectionStyles';
import { createIcon } from '../../../utils';
import { CategoryTile } from '../../controls/CategoryTile';
import { WaitForCondition } from '../WaitForCondition';
import { useRecommendations } from '../../../state';

export const CategoriesSection: React.FC = () => {
  const { categories } = useCategories();
  const { recommendations } = useRecommendations();
  return (
    <Root>
      <CategoriesRow>
        <WaitForCondition condition={Boolean(categories)}>
          {categories && categories.map((category) => 
            <CategoryTile
              id={category.id}
              key={category.id}
              title={category.name}
              icon={createIcon(category.icon)}
              count={recommendations.filter(r => r.category === category.id).length}
            />
          )}
        </WaitForCondition>
      </CategoriesRow>
    </Root>
  );
};
