import { Recommendation } from '@recommendations/model';
import { toRecommendation } from './getRecommendation';
import { domain } from './domain';

export interface GetRecommendationsOptions {
  readonly searchString?: string;
  readonly category?: string;
}

type PairMap = { [key: string]: string };

const toPairFrom = (map: PairMap) => (key: string) => `${key}=${map[key]}`;

const whereKeyHasValueIn = (map: PairMap) => (key: string) => map[key];

export const getRecommendations = (options?: GetRecommendationsOptions ): Promise<ReadonlyArray<Recommendation>> => {
  const map = options as { [key: string]: string };
  const keys = options && Object.keys(options) ;
  const params = keys && keys.length > 0 ? keys
    .filter(whereKeyHasValueIn(map))
    .map(toPairFrom(map)).join('&') : '';
  const queryString = params.length > 0 ? `?${params}` : '';
  return fetch(`${domain}/api/v1/recommendations${queryString}`)
    .then(body => body.json())
    .then(jsonCollection => jsonCollection.map((json: any) => toRecommendation(json)))
};
