import styled from 'styled-components';
import { Themed } from 'packages/app/src/theme';

export const Title = styled.h2`
  color: ${(props: Themed) => props.theme.colors.a};
  font-size: 2.5em;
  display: block;
  margin-top: 0.5em;
  margin-left: 0.25em;
  @media (max-width: ${(props: Themed) => props.theme.breakpoints.xs}) {
    display: inline-block;
    margin-left: 1em;
    max-width: 5em;
  }
`;

export const TitleSection = styled.section`
  text-align: center;
  padding: 1em;
  h2 {
    margin-left: 1em;
    display: inline-block;
  }
`;
